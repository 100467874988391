import {languages} from '../models/languages';
import {APP_CONST} from "../AppConstants";
import {createSlice} from "@reduxjs/toolkit";

let isMobileViewDefault = true;
let isXLViewDefault = false;
let isLGViewDefault = false;
let isMDViewDefault = false;
let isSMViewDefault = false;
let isXSViewDefault = false;

if (window?.innerWidth > 992) {
  isMobileViewDefault = false;
}

if (window?.innerWidth > 1200) {
  isXLViewDefault = true;
}

if (window?.innerWidth >= 992 && window?.innerWidth <= 1200) {
  isLGViewDefault = true;
}

if (window?.innerWidth >= 768 && window?.innerWidth < 992) {
  isMDViewDefault = true;
}

if (window?.innerWidth >= 576 && window?.innerWidth < 768) {
  isSMViewDefault = true;
}

if (window?.innerWidth < 576) {
  isXSViewDefault = true;
}



const appSlice = createSlice({
  name: 'appReducer',
  initialState: {
    toastStatus: {
      type: null,
      isVisible: false,
      timeout: 7000,
      message: '',
    },

    systemError: {
      isVisible: false,
      errorObject: null,
    },
    appLoader: {
      isVisible: false,
      type: 'screen'
    },
    appLanguages: languages.languageOptions,
    activeLanguage: languages.defaultActiveLanguage,
    deviceInfo: {
      isMobileView: isMobileViewDefault,
      isXLView: isXLViewDefault,
      isLGView: isLGViewDefault,
      isMDView: isMDViewDefault,
      isSMView: isSMViewDefault,
      isXSView: isXSViewDefault,
      windowHeight:0,
      isMobileDevice: false,
    },
    prevLocation: {},
    currentPathname: "",
    isRedirect: false,
    postLoginUrl: "",
    postLoginUrlProps: {},

    drawerWidth2: APP_CONST.FULL_DRAWER_WIDTH,
    isDrawerOpen: false,
    isHamburgerMenuOpen: false
  },
  reducers: {
    setCurrentPathNameData(state, action) {
      state.currentPathname= action.payload
    },
    changeDrawerWidthData(state, action) {
      let tempObj = {
        drawerWidth2: APP_CONST.FULL_DRAWER_WIDTH,
        isDrawerOpen: true
      };
      if(state.deviceInfo.isMobileView){

        tempObj = {
          isDrawerOpen: false
        }

      } else {
        if(isEmpty(action.payload)){

          tempObj = {
            drawerWidth2: (state.drawerWidth2 === APP_CONST.FULL_DRAWER_WIDTH ? APP_CONST.HALF_DRAWER_WIDTH : APP_CONST.FULL_DRAWER_WIDTH),
            isDrawerOpen: true
          }

        } else {
          tempObj = {
            drawerWidth2: action.payload,
            isDrawerOpen: true
          }
        }
      }
      return {
        ...tempObj
      };
    },

    changeDrawerStatusData(state, action) {
      state.isDrawerOpen= action.payload
    },

    changeHamburgerMenuStatus(state, action) {
      state.isHamburgerMenuOpen= action.payload
    },
    setAppLoaderData(state, action) {
      state.sappLoader={
        type:action.payload.type,
        isVisible: action.payload.isVisible,
      }
    },
    AppResize(state, action) {
      state.deviceInfo={
        ...state.deviceInfo,
        isMobileView: action.payload.isMobileView,
        isDrawerOpen: (action.payload.isMobileView ? false : state.isDrawerOpen),
        isXLView: action.payload.isXLView,
        isLGView: action.payload.isLGView,
        isMDView: action.payload.isMDView,
        isSMView: action.payload.isSMView,
        isXSView: action.payload.isXSView,
        windowHeight: action.payload.windowHeight
      }

    },
    showToastMessageData(state, action) {
      state.toastStatus= {
        ...state.toastStatus,
        ...action.payload,
        isVisible: true,
      }
    },
    hideToastMessageData(state,) {
      state.toastStatus={
        ...state.toastStatus,
        type: null,
        message: '',
        isVisible: false,
      }
    },
    showSystemErrorBlockData(state, action) {
      state.systemError= {
        isVisible: true,
        errorObject: action.payload
      }
    },
    hideSystemErrorBlockData(state, ) {
      state.systemError= {
        isVisible: false,
        errorObject: null
      }
    },

    userLogout(state, ) {
      state.deviceInfo= {
        ...state.deviceInfo,
      }
    },
    setPrevLocationData(state, action) {
      state.prevLocation=action.payload
    },

    setPostLoginUrlData(state, action) {
      state.isRedirect = true;
      state.postLoginUrl = action.payload.postLoginUrl;
      state.postLoginUrlProps = action.payload.postLoginUrlProps
    },
    clearPostLoginUrlData(state) {
      state.isRedirect=false;
      state.postLoginUrl= "";
      state.postLoginUrlProps= {};
    },
    changeSocketStatus(state, action) {
      state.socket={
        ...state.socket,
        socketStatus: action.payload,
      }
    },
    changeAppRoomJoinedStatus(state, action){
      state.socket={
        ...state.socket,
        appRoomJoined: action.payload,
      }
    },
    changeSocketDataObject(state, action){
      state.socket={
        ...state.socket,
        latestSocketData: action.payload,
      }
    },

    changeAccountRoomJoinedStatus(state, action){
      state.socket={
        ...state.socket,
        isAccountRoomJoined: action.payload,
      }
    },

    setMobileDevice(state, action){
      state.deviceInfo= {
        ...state.deviceInfo,
        isMobileDevice:action.payload
      }
    }
  },
});

export const {setCurrentPathNameData,hideSystemErrorBlockData,hideToastMessageData,
  setAppLoaderData,AppResize,showToastMessageData,showSystemErrorBlockData,setPrevLocationData,setMobileDevice,
} = appSlice.actions;
export default appSlice.reducer;


