import React, {useRef, useState} from 'react';
import {Link, NavLink} from "react-router-dom";
import useOnClickOutSide from "../../components/Hooks/useClickOutside";
import "./menuBar.css"

function Hamburger(props) {
    const {handleHamburgerClick} = props;
    const [isFeatureOpen, setIsFeatureOpen] = useState(false);
    const [isIndustriesOpen, setIsIndustriesOpen] = useState(false);
    const ref = useRef();
    const currentPath = (props.location.pathname);

    const handleFeatureClick = () => {
        setIsFeatureOpen(!isFeatureOpen)
    };

    const handleIndustriesClick = () => {
        setIsIndustriesOpen(!isIndustriesOpen)
    };

    useOnClickOutSide(ref, () => {
        handleHamburgerClick()
    });

    return (
        <div className='theme-bg-white position-fixed h-100 position-fixed top-0 '
             style={{
                 width: 300,
                 right: 0,
                 height: 500
             }}
             ref={ref}>
            <div className='bg-white' style={{height:'100vh'}}>
                <div className='py-3  pe-3 mx-3 text-end'>
                    <Link className='remove-underline  theme-text-black theme-font-normal theme-font-content'
                          to={'/'}>
                        <i className="fa-solid fa-xmark" onClick={()=>{handleHamburgerClick()}} />
                    </Link>
                </div>
                <div className='py-3  pe-3 mx-3'
                     style={{borderBottom: '1px solid #c5c5c5'}}
                     onClick={() => {handleHamburgerClick()}}>
                    <Link  className={currentPath === "/" ? 'menu-item-active remove-underline' : 'remove-underline  theme-text-black theme-font-normal theme-font-content'}
                          to={'/'}>
                        Home
                    </Link>
                </div>
                <div className='py-3 mx-3 pe-3'
                     style={{borderBottom: '1px solid #c5c5c5'}}
                     onClick={() => {handleHamburgerClick()}}>
                    <Link  className={currentPath === "/services/technology-partnership" ? 'menu-item-active remove-underline' : 'remove-underline  theme-text-black theme-font-normal theme-font-content'}
                           to={'/services/technology-partnership'}>
                        Technology Partnership
                    </Link>
                </div>
                <div className='py-3 mx-3 pe-3'
                     onClick={() => {handleHamburgerClick()}}>
                    <Link   className={currentPath === "/services/custom-software-development" ? 'menu-item-active remove-underline' : 'remove-underline  theme-text-black theme-font-normal theme-font-content'}
                          to={'/services/custom-software-development'}>
                        Custom Software Development
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default (Hamburger);
