import React, {useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";
import useOnClickOutSide from "../../components/Hooks/useClickOutside";
import './menuBar.css'
import {redirectTo} from "../../../utils/helper";
import Hamburger from "./hamburger";
import './header.css'
import appTheme from "../../../assets/appTheme";
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from "../../../assets/img/deccanLogicLogo.jpg";
import {withTranslation} from "react-i18next";


function Header(props) {
    const [isOpenMenuBar, setIsOpenMenuBar] = useState(false);
    const [openSideMenuBar, setOpenSideMenuBar] = useState(false);
 const {isMobileView} =props;
    const ref = useRef();
    window.onscroll = function() {scrollFunction()};
    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.getElementById("header").style.borderBottom = "0.50px solid #1671DD";
        } else {
            document.getElementById("header").style.borderBottom = "none";
        }
    }
    const handleItemOpen = () => {
        setIsOpenMenuBar(!isOpenMenuBar)
    };

    const handleCloseItem = () => {
        setIsOpenMenuBar(false)
    };

    const handleHamburgerClick = () => {
        setOpenSideMenuBar(!openSideMenuBar)
    };

    useOnClickOutSide(ref, () => {
        handleCloseItem()
    });

    const renderFeatures = () => {
        return (
            <div ref={ref}>
                <div className='cursor'>
                    <div style={{cursor: 'pointer'}}
                         className='theme-font-semiBold theme-text-primary theme-font-semiBold label cursor'
                         onClick={() => {
                             handleItemOpen()
                         }}>Features
                    </div>
                </div>
                {isOpenMenuBar &&
                <ul className="featureMenuBar">
                    <li style={{paddingTop: 6}}>
                        <Link to={"/features/data-setup"}
                              className='actionable-link theme-text-primary'
                              onClick={() => {
                                  handleCloseItem()
                              }}>
                            <span className={currentPath === "/features/data-setup" ? 'feature-menu-selected' : ''}>Data Setup</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/features/segmentation"}
                              className='actionable-link theme-text-primary'
                              onClick={() => {
                                  handleCloseItem()
                              }}>
                            <span
                                className={currentPath === "/features/segmentation" ? 'feature-menu-selected' : ''}>Segmentation</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={"/features/campaigns"}
                              className='actionable-link theme-text-primary'
                              onClick={() => {
                                  handleCloseItem()
                              }}>
                            <span className={currentPath === "/features/campaigns" ? 'feature-menu-selected' : ''}>Campaign Management</span>
                        </Link>
                    </li>
                    <li style={{paddingBottom: 6}}>
                        <Link to={"/features/email-templates"}
                              className='actionable-link theme-text-primary'
                              onClick={() => {
                                  handleCloseItem()
                              }}>
                            <span
                                className={currentPath === "/features/email-templates" ? 'feature-menu-selected' : ''}>Email Templates</span>
                        </Link>
                    </li>
                </ul>
                }
            </div>
        );
    };


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const currentPath = (props.location.pathname);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <div className="d-flex align-items-center justify-content-between block-container h-100 w-100">
                        <div>
                            <a href='/'>
                            <img src={Logo}
                                 height={29}
                                 style={{marginLeft: -10}}/>
                            </a>
                        </div>
                        <div>
                            <i className="fa-solid fa-bars" style={{fontSize: 16}}
                               onClick={() => handleHamburgerClick()}/>
                        </div>

                        {openSideMenuBar &&
                        <div className='bg-white w-100 d-flex justify-content-end h-100'>
                            <Hamburger {...props}
                                       handleHamburgerClick={handleHamburgerClick}
                                       useOnClickOutSide={useOnClickOutSide}
                            />
                        </div>
                        }
                    {/*</div>*/}
                </div>
            )
        }

        return (
                <div className="d-flex align-items-center block-container h-100 pe-0">
                    <div className=''>
                        <a href='/'>
                            <img src={Logo}
                                 style={{marginLeft: -10}}/>
                        </a>
                    </div>
                    <div className="d-flex align-items-center" style={{gap: "50px", paddingLeft: 58}}>
                        <nav id='app-header-nav'>
                            <NavLink exact  to="/">
                                Home
                            </NavLink>
                            <NavLink to="/services/technology-partnership">
                                Technology Partnership
                            </NavLink>
                            <NavLink to="/services/custom-software-development">
                                Custom Software Development
                            </NavLink>
                        </nav>
                    </div>

                </div>
        )
    };
    return (
        <div id={'header'}
             className='w-100 theme-bg-white position-fixed top-0 content'
             style={{
                 zIndex: 999, height: isMobileView ? 50 : 80,
                 filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.10))'
             }}>
            {renderUI()}
        </div>
    );
}

Header.propTypes = {};

Header.defaultProps = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Header));
