import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import {Provider} from "react-redux";
import {getAppEnvironmentVariables} from "./utils/httpHelper";
import * as log from 'loglevel';
import AppNavigator from "./AppNavigator";
import appTheme from "./assets/appTheme";
import {debounce} from "./utils/helper";
import {AppResize, setMobileDevice} from "./core/reducers/appReducer";
import TagManager from 'react-gtm-module';
import store from './store'

let windowInnerWidth = 0;

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
};

const App = () => {
    const setMobileView = debounce(() => {

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

        const currentWindowInnerWidth = window.innerWidth;
        store.dispatch(AppResize({
            isXLView: window.innerWidth > 1200,
            isLGView: window.innerWidth >= 992 && window?.innerWidth <= 1200,
            isMDView: window.innerWidth >= 768 && window?.innerWidth <= 992,
            isSMView: window.innerWidth >= 576 && window?.innerWidth <= 768,
            isMobileView: window.innerWidth <= 992,
            isXSView: window.innerWidth < 576,
            windowHeight:window.innerHeight
        }));
        //reduce height as per window height(while search bar is open and close)
        if (windowInnerWidth === 0 || currentWindowInnerWidth !== windowInnerWidth){
            if (isMobile || isIpad){
                let vh = window.innerHeight ;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            }
        }

        if (isMobile || isIpad){
            store.dispatch(setMobileDevice(true))
        }else {
            store.dispatch(setMobileDevice(false))
        }

    }, 300);

    const zoomOutMobile=()=>{
        var viewport = document.querySelector('meta[name="viewport"]');

        if (!viewport) {
            return;
        }

        if (window.innerWidth >= 384) {
            return;
        }

        let initScale = window.innerWidth / 384;
        initScale = Math.round(initScale * 100) / 100;

        viewport.content = "initial-scale=" + initScale;
    };

    useEffect(()=>{
        const {REACT_APP_ENVIRONMENT, REACT_APP_GOOGLE_TAG_MANAGER_ID} = getAppEnvironmentVariables();
        zoomOutMobile();
        setMobileView();//for the first time
        console.log('REACT_APP_ENVIRONMENT ', REACT_APP_ENVIRONMENT);
        console.log('REACT_APP_GOOGLE_TAG_MANAGER_ID ', REACT_APP_GOOGLE_TAG_MANAGER_ID);
        if(REACT_APP_ENVIRONMENT==="PROD"){
            TagManager.initialize({
                gtmId: REACT_APP_GOOGLE_TAG_MANAGER_ID
            });
            console.log('GTM initialized ');
        }

        window.addEventListener('resize', setMobileView);
        return ()=>{
            window.removeEventListener('resize', setMobileView);
        }
    },[]);

    const [isThemeLoaded, setIsThemeLoaded] = useState(false);

    useEffect(() => {
        const {REACT_APP_ENVIRONMENT} = getAppEnvironmentVariables();
        if (REACT_APP_ENVIRONMENT === "DEVELOPMENT" || REACT_APP_ENVIRONMENT === "QA") {
            log.setLevel("DEBUG");
        }

        setTimeout(() => {
            setIsThemeLoaded(true)
        }, 100)
    }, []);

    const renderMainContent = () => {

        if (!isThemeLoaded) {
            return <></>
        }

        return (<div className='selectionRemove'>
            <AppNavigator />
        </div>)
    };

    return (
        <div style={{...appTheme.default}}>
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <Router>
                        {renderMainContent()}
                    </Router>
                </Provider>
            </I18nextProvider>
        </div>
    );
};
export default App;
