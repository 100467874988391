import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {setPrevLocation} from "../../core/actions/appActions";
import Footer from "../../core/layout/footer/footer";
import "./aboutUs.css";
import "../../assets/css/appTheme.css";
import "../../assets/css/contentApp.css"
import CEOImg from "../../assets/img/about us/sandeepTendulkar.png";
import CTOImg from "../../assets/img/about us/abhayParab.png";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function AboutUs(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const {isMobileView, isMDView} = props;
    const renderUI = () => {
        if (isMobileView) {
            return (
                <div>
                    <section id={'about-us-banner-mobile'}>
                        <div className="about-us-banner-bg d-flex align-items-center  ">
                            <div>
                                <div className="about-us-banner-container py-3 px-1 m-3">
                                    <h1 className="theme-font-page-title pb-3">
                                        Vision
                                    </h1>
                                    <h4 className="theme-font-content-md theme-font-light theme-content-line-height ">
                                        To democratize enterprise quality technology services for the benefit of broader
                                        society
                                        and businesses
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id={'about-team-mobile'}>
                        <div className="block-container padding-block-top padding-block-bottom">
                            <h2 className=" theme-font-section-title theme-font-semiBold pb-50">
                                Executive Team
                            </h2>
                            <div className="d-flex flex-column" style={{gap: 24}}>
                                <div className={isMDView ? 'w-50':'w-100'}>
                                    <img src={CEOImg}
                                         className="img-fluid px-5"/>
                                    <h4 className="theme-font-content-md theme-font-semiBold theme-content-line-height py-3">
                                        Sandeep Tendulkar
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        Founder & CEO
                                    </h4>
                                </div>
                                <div>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height ">
                                        Sandeep is an Information Technology Specialist and a business leader with more
                                        than
                                        25 years of experience. He has experience of 20 years in corporate world and 5+
                                        years in start-up world.He has managed global delivery teams distributed across
                                        multiple geographies for marquee clients in Financial Services. In the start-up
                                        world, he has demonstrated his ability to design, build and deploy cutting edge
                                        technology solutions.He is very passionate about democratising technology
                                        services
                                        for the benefit of society and businesses.
                                    </h4>
                                </div>
                            </div>
                            <div className="linear-gradient-border"
                                 style={{marginTop: 70, marginBottom: 70}}/>
                            <div className="d-flex flex-column"
                                 style={{gap: 24}}>
                                <div className={isMDView ? 'w-50':'w-100'}>
                                    <img src={CTOImg}
                                         className="img-fluid px-5"/>
                                    <h4 className="theme-font-content-md theme-font-semiBold theme-content-line-height py-3">
                                        Abhay Parab
                                    </h4>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        Partner & CTO
                                    </h4>
                                </div>
                                <div>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        Abhay is a technologist who loves working at the intersection of Engineering,
                                        AI,
                                        Big Data & Cloud computing using various open sources to drive innovations. He
                                        has
                                        more than 20 years of expertise which spans across Technology and Analytics
                                        service
                                        providers, product companies as well as startups.He was Head of Data Engineering
                                        for
                                        Tata Digital. For Tata Super App, he was responsible for creation of data
                                        platform
                                        and golden consumer view across all the participating companies. He was Chief
                                        Architect for Cuddle.ai (now Crux Intelligence) where he conceptualized and
                                        built
                                        AI-powered business analytics platform that understands natural language
                                        questions
                                        and automatically creates relevant insights on the fly. At Fractal Analytics,
                                        Abhay
                                        had built Concordia - Big data harmonization and integration platform that
                                        enables
                                        faster & automated data integration from multiple big data sources.Abhay is keen
                                        on
                                        democratizing Data, AI & Engineering solutions for enabling continuous
                                        innovations.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )
        }
        return (
            <div>
                <section id={'about-us-banner'}>
                    <div className="about-us-banner-bg block-container d-flex align-items-center">
                        <div>
                            <div className="about-us-banner-container py-3">
                                <h1 className="theme-font-page-title pb-4">
                                    Vision
                                </h1>
                                <div className="">
                                    <h4 className="theme-font-section-sub-header theme-font-light "
                                        style={{maxWidth: '60%'}}>
                                        To democratize enterprise quality technology services for the benefit of broader
                                        society
                                        and businesses
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id={'about-team'}>
                    <div className="block-container padding-block-top padding-block-bottom">
                        <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                            Executive Team
                        </h2>
                        <div className="d-flex padding-block-bottom" style={{gap: 50}}>
                            <div style={{flex: 2}}>
                                <img src={CEOImg}
                                     style={{marginTop: -23}}
                                     className="img-fluid"/>
                                <h4 className="theme-font-content theme-font-bold theme-content-line-height"
                                    style={{paddingTop: 20, whiteSpace: "nowrap"}}>
                                    Sandeep Tendulkar
                                </h4>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                    Founder & CEO
                                </h4>
                            </div>
                            <div style={{flex: 9}}>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                    Sandeep is an Information Technology Specialist and a business leader with more than
                                    25 years of experience. He has experience of 20 years in corporate world and 5+
                                    years in start-up world.He has managed global delivery teams distributed across
                                    multiple geographies for marquee clients in Financial Services. In the start-up
                                    world, he has demonstrated his ability to design, build and deploy cutting edge
                                    technology solutions.He is very passionate about democratising technology services
                                    for the benefit of society and businesses.
                                </h4>
                            </div>
                        </div>
                        <div className="d-flex" style={{gap: 50}}>
                            <div style={{flex: 9}}>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                    Abhay is a technologist who loves working at the intersection of Engineering, AI,
                                    Big Data & Cloud computing using various open sources to drive innovations. He has
                                    more than 20 years of expertise which spans across Technology and Analytics service
                                    providers, product companies as well as startups.He was Head of Data Engineering for
                                    Tata Digital. For Tata Super App, he was responsible for creation of data platform
                                    and golden consumer view across all the participating companies. He was Chief
                                    Architect for Cuddle.ai (now Crux Intelligence) where he conceptualized and built
                                    AI-powered business analytics platform that understands natural language questions
                                    and automatically creates relevant insights on the fly. At Fractal Analytics, Abhay
                                    had built Concordia - Big data harmonization and integration platform that enables
                                    faster & automated data integration from multiple big data sources.Abhay is keen on
                                    democratizing Data, AI & Engineering solutions for enabling continuous innovations.
                                </h4>
                            </div>
                            <div style={{flex: 2}}>
                                <img src={CTOImg}
                                     style={{marginTop: -23}}
                                     className="img-fluid"/>
                                <h4 className="theme-font-content theme-font-bold theme-content-line-height"
                                style={{paddingTop:20}}>
                                    Abhay Parab
                                </h4>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                    Partner & CTO
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    };
    return (
        <>
            <div className="w-100">
                {renderUI()}

                <Footer {...props}/>
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isMDView: state.appState.deviceInfo.isMDView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {
    setPrevLocation
})(withRouter(AboutUs));
