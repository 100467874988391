import { configureStore } from '@reduxjs/toolkit'
import appReducer from "../src/core/reducers/appReducer"


const store = configureStore({
  reducer: {
    appState: appReducer,
  }
});
export default store;
