import React, {createRef, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Footer from "../../core/layout/footer/footer";
import {showToastMessage} from "../../core/actions/appActions";
import {getTicketFor, raisePreLoginTicket} from "./contactUsActions";
import {APP_CONST} from "../../core/AppConstants";
import {isEmpty} from "../../utils/validations";
import {getRandomId, isValidEmail} from "../../utils/helper";
import store from './../../store';
import appTheme from "../../assets/appTheme";
import {Link} from "react-router-dom";
import Email from "../../assets/img/ContactUs/email.png";
import Phone from "../../assets/img/ContactUs/phone.png";
import Location from "../../assets/img/ContactUs/address.png";
import "./contactUs.css"

const ContactUsView = (props) => {
    let scrollTo = {
        name: createRef(),
        email: createRef(),
        phoneNumber: createRef(),
        website: createRef(),
        description: createRef()
    };

    const {
        isMobileView, isMDView, isXSView, isSMView, handleChange, name, nameError, emailError, email, website, websiteError, description, descriptionError,
        phoneNumber, phoneNumberError, handleSubmit, onBlurInputText
    } = props;

    useEffect(() => {
        if (scrollTo[props.scrollToElement]?.current) {
            let topOffset = isMobileView? 50 : 90;
            let element = scrollTo[props.scrollToElement].current;

            if (element) {
                if (Math.abs(element.getBoundingClientRect().top) < topOffset + 3) {
                    // dont scroll already on top
                } else {
                    window.focus();
                    window.scrollTo({
                        top: element.getBoundingClientRect().top + window.scrollY - topOffset,
                        behavior: 'smooth',
                    });
                }
            }
        }
    }, [props.scrollToElement]);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section id={'contact-us-banner'}
                             className="contact-us-banner-bg block-container d-flex justify-content-center align-items-center">
                        <div className="py-3">
                            <h1 className="theme-font-page-title pb-4">
                                Contact Us
                            </h1>

                        </div>
                    </section>
                    <section id={'get-in-touch'}>
                        <div className="block-container padding-block-top padding-block-bottom">
                            <div className="row">
                                <div className="padding-block-bottom">
                                    <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                                        Get In Touch
                                    </h2>
                                    <div className={"row "}>
                                        <div className={"col-12"} ref={scrollTo['name']}>
                                        <span className='theme-font-semiBold theme-font-content'
                                              style={{
                                                  marginBottom: '4px',
                                                  color: (nameError ? 'var(--error)' : 'var(--black-text)')
                                              }}
                                        >
                                        Name<span className='theme-text-error'>*</span></span>
                                            <input onChange={(value) => handleChange('name', value.target.value)}
                                                   type="text"
                                                   style={{
                                                       border: '1px solid rgba(0, 0, 0, 0.23)',
                                                       borderColor: (nameError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                       borderRadius: '8px',
                                                       padding: '7px 14px',
                                                       width: '100%',
                                                       fontWeight: '500',
                                                       color: 'var(--black-text)'
                                                   }}
                                                   placeholder={" "}
                                                   value={name}
                                                   onBlur={() => onBlurInputText('name')}
                                                   maxLength={51}
                                            />
                                            <span
                                                className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                                style={{paddingLeft: '5px', minHeight: '19px'}}>
                                                <span>{nameError}</span>
                                        </span>
                                        </div>
                                        <div className={"col-12"} ref={scrollTo['email']}>
                                     <span className='theme-font-semiBold theme-font-content'
                                           style={{
                                               marginBottom: '4px',
                                               color: (emailError ? 'var(--error)' : 'var(--black-text)')
                                           }}>E-Mail<span className='theme-text-error'>*</span></span>
                                            <input
                                                onChange={(value) => handleChange('email', value.target.value)}
                                                type="text"
                                                style={{
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderColor: (emailError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                    borderRadius: '8px',
                                                    padding: '7px 14px',
                                                    width: '100%',
                                                    fontWeight: '500',
                                                    color: 'var(--black-text)'
                                                }}
                                                placeholder={" "}
                                                value={email}
                                                onBlur={() => onBlurInputText('email')}
                                                maxLength={51}
                                            />
                                            <span
                                                className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                                style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{emailError}</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div className={"row "}>
                                        <div className={"col-12"} ref={scrollTo['phoneNumber']}>
                                        <span className='theme-font-semiBold theme-font-content'
                                              style={{
                                                  marginBottom: '4px',
                                                  color: (phoneNumberError ? 'var(--error)' : 'var(--black-text)')
                                              }}
                                        >
                                        Phone Number<span style={{color: 'var(--error)'}}>*</span></span>
                                            <input onChange={(value) => handleChange('phoneNumber', value.target.value)}
                                                   type="text"
                                                   style={{
                                                       border: '1px solid rgba(0, 0, 0, 0.23)',
                                                       borderColor: (phoneNumberError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                       borderRadius: '8px',
                                                       padding: '7px 14px',
                                                       width: '100%',
                                                       fontWeight: '500',
                                                       color: 'var(--black-text)'
                                                   }}
                                                   placeholder={" "}
                                                   value={phoneNumber}
                                                   onBlur={() => onBlurInputText('phoneNumber')}
                                                   maxLength={51}
                                            />
                                            <span
                                                className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                                style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{phoneNumberError}</span>
                                        </span>
                                        </div>
                                        <div className={"col-12"} ref={scrollTo['website']}>
                                     <span className='theme-font-semiBold theme-font-content'
                                           style={{
                                               marginBottom: '4px',
                                               color: (websiteError ? 'var(--error)' : 'var(--black-text)')
                                           }}>Your Website</span>
                                            <input
                                                onChange={(value) => handleChange('website', value.target.value)}
                                                type="text"
                                                style={{
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderColor: (websiteError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                    borderRadius: '8px',
                                                    padding: '7px 14px',
                                                    width: '100%',
                                                    fontWeight: '500',
                                                    color: 'var(--black-text)'
                                                }}
                                                placeholder={" "}
                                                value={website}
                                                onBlur={() => onBlurInputText('website')}
                                                maxLength={51}
                                            />
                                            <span
                                                className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                                style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{websiteError}</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-12"} ref={scrollTo['description']}>
                                     <span className='theme-font-semiBold theme-font-content'
                                           style={{
                                               marginBottom: '4px',
                                               color: (descriptionError ? 'var(--error)' : 'var(--black-text)')
                                           }}>
                                            Description<span className='theme-text-error'>*</span></span>
                                            <textarea rows="3"
                                                      onChange={(value) => handleChange('description', value.target.value)}
                                                      style={{
                                                          border: '1px solid rgba(0, 0, 0, 0.23)',
                                                          borderColor: (descriptionError ? 'red' : ' rgba(0, 0, 0, 0.23)'),
                                                          borderRadius: '8px',
                                                          padding: '7px 14px',
                                                          width: '100%',
                                                          fontWeight: '500',
                                                          color: 'var(--black-text)',
                                                          maxHeight: '91px',
                                                          resize: 'none'
                                                      }}
                                                      value={description}
                                                      onBlur={() => onBlurInputText('description')}
                                                      maxLength={501}>
                                         </textarea>

                                            <span
                                                className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                                style={{paddingLeft: '15px', minHeight: '19px'}}>
                                        <span>{descriptionError}</span>
                                        </span>
                                        </div>
                                    </div>
                                    <input type={'button'}
                                           className="button-style w-100"
                                           value={'Submit'}
                                           onClick={() => {
                                               handleSubmit()
                                           }}>

                                    </input>
                                </div>

                            </div>
                            <div className="">
                                <div className="panel pt-40 pb-40 px-3 h-100">
                                    <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                                        Let’s Talk
                                    </h2>
                                    <div className="pb-60">
                                        <img src={Email}
                                             height={56}
                                             width={85}/>
                                        <h3 className="theme-font-content-md py-1">
                                            E-Mail
                                        </h3>
                                        <a style={{textDecoration: 'none'}}
                                           href={"mailto:" + 'info@deccanlogic.com'}>info@deccanlogic.com </a>
                                    </div>
                                    <div>
                                        <img src={Phone}
                                             height={56}
                                             width={41}/>
                                        <h3 className="theme-font-content-md py-1">
                                            Phone
                                        </h3>
                                        <a style={{textDecoration: 'none'}}
                                           href={"tel:" + 919322213960}>+919322213960</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id={'location'} className="block-container padding-block-bottom">
                        <div className="d-flex flex-column padding-block-bottom">
                            <img src={Location}
                                 height={56}
                                 width={41}/>
                            <h3 className="theme-font-content-md py-1">
                                Address
                            </h3>
                            <h4 className="theme-font-content">
                                Office # 05, 4ᵗʰ Floor, City Vista - B, Fountain Road, Kharadi, Pune-411014,
                                Maharashtra, India.
                            </h4>
                        </div>
                        <div className="w-100">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.2768035138283!2d73.94211117612926!3d18.561555367919283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c3dcc4f0e6f7%3A0xa7058ccb94fa7be0!2sDeccanlogic%20Private%20Limited!5e0!3m2!1sen!2sin!4v1694422290844!5m2!1sen!2sin"
                                width="100%"
                                height="450"
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"/>
                        </div>
                    </section>

                </>
            )
        }

        return (
            <>
                <section id={'contact-us-banner'}
                         className="contact-us-banner-bg block-container d-flex align-items-center">
                    <div className="py-3">
                        <div className="theme-font-page-title pb-4 cursor-none">
                            Contact Us
                        </div>

                    </div>
                </section>
                <section id={'get-in-touch'}>
                    <div className="block-container padding-block-top padding-block-bottom">
                        <div className="row">
                            <div className="col-8">
                                <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                                    Get In Touch
                                </h2>
                                <div className={"row "}>
                                    <div className={"col-6"} ref={scrollTo['name']}>
                                        <span className='theme-font-semiBold theme-font-content'
                                              style={{
                                                  marginBottom: '4px',
                                                  color: (nameError ? 'var(--error)' : 'var(--black-text)')
                                              }}
                                        >
                                        Name<span className='theme-text-error'>*</span></span>
                                        <input
                                            onChange={(value) => {
                                                handleChange('name', value.target.value)
                                            }}
                                            type="text"
                                            style={{
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                borderColor: (nameError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                borderRadius: '8px',
                                                padding: '7px 14px',
                                                width: '100%',
                                                fontWeight: '500',
                                                color: 'var(--black-text)'
                                            }}
                                            placeholder={" "}
                                            value={name}
                                            onBlur={() => onBlurInputText('name')}
                                            maxLength={51}
                                        />
                                        <span
                                            className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                            style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{nameError}</span>
                                        </span>
                                    </div>
                                    <div className={"col-6"} ref={scrollTo['email']}>
                                     <span className='theme-font-semiBold theme-font-content'
                                           style={{
                                               marginBottom: '4px',
                                               color: (emailError ? 'var(--error)' : 'var(--black-text)')
                                           }}>E-Mail<span className='theme-text-error'>*</span></span>
                                        <input
                                            onChange={(value) => handleChange('email', value.target.value)}
                                            type="text"
                                            style={{
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                borderColor: (emailError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                borderRadius: '8px',
                                                padding: '7px 14px',
                                                width: '100%',
                                                fontWeight: '500',
                                                color: 'var(--black-text)'
                                            }}
                                            placeholder={" "}
                                            value={email}
                                            onBlur={() => onBlurInputText('email')}
                                            maxLength={51}
                                        />
                                        <span
                                            className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                            style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{emailError}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className={"row "}>
                                    <div className={"col-6"} ref={scrollTo['phoneNumber']}>
                                        <span className='theme-font-semiBold theme-font-content'
                                              style={{
                                                  marginBottom: '4px',
                                                  color: (phoneNumberError ? 'var(--error)' : 'var(--black-text)')
                                              }}
                                        >
                                        Phone Number<span style={{color: 'var(--error)'}}>*</span></span>
                                        <input onChange={(value) => handleChange('phoneNumber', value.target.value)}
                                               type="text"
                                               style={{
                                                   border: '1px solid rgba(0, 0, 0, 0.23)',
                                                   borderColor: (phoneNumberError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                   borderRadius: '8px',
                                                   padding: '7px 14px',
                                                   width: '100%',
                                                   fontWeight: '500',
                                                   color: 'var(--black-text)'
                                               }}
                                               placeholder={" "}
                                               value={phoneNumber}
                                               onBlur={() => onBlurInputText('phoneNumber')}
                                               maxLength={51}
                                        />
                                        <span
                                            className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                            style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{phoneNumberError}</span>
                                        </span>
                                    </div>
                                    <div className={"col-6"} ref={scrollTo['website']}>
                                     <span className='theme-font-semiBold theme-font-content'
                                           style={{
                                               marginBottom: '4px',
                                               color: (websiteError ? 'var(--error)' : 'var(--black-text)')
                                           }}>Your Website</span>
                                        <input
                                            onChange={(value) => handleChange('website', value.target.value)}
                                            type="text"
                                            style={{
                                                border: '1px solid rgba(0, 0, 0, 0.23)',
                                                borderColor: (websiteError ? '#D72F2F' : 'rgba(0, 0, 0, 0.23)'),
                                                borderRadius: '8px',
                                                padding: '7px 14px',
                                                width: '100%',
                                                fontWeight: '500',
                                                color: 'var(--black-text)'
                                            }}
                                            placeholder={" "}
                                            value={website}
                                            onBlur={() => onBlurInputText('website')}
                                            maxLength={51}
                                        />
                                        <span
                                            className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                            style={{paddingLeft: '5px', minHeight: '19px'}}>
                                        <span>{websiteError}</span>
                                        </span>
                                    </div>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-12"} ref={scrollTo['description']}>
                                     <span className='theme-font-semiBold theme-font-content'
                                           style={{
                                               marginBottom: '4px',
                                               color: (descriptionError ? 'var(--error)' : 'var(--black-text)')
                                           }}>
                                            Description<span className='theme-text-error'>*</span></span>
                                        <textarea rows="3"
                                                  onChange={(value) => handleChange('description', value.target.value)}
                                                  style={{
                                                      border: '1px solid rgba(0, 0, 0, 0.23)',
                                                      borderColor: (descriptionError ? 'red' : ' rgba(0, 0, 0, 0.23)'),
                                                      borderRadius: '8px',
                                                      padding: '7px 14px',
                                                      width: '100%',
                                                      fontWeight: '500',
                                                      color: 'var(--black-text)',
                                                      maxHeight: '91px',
                                                      resize: 'none'
                                                  }}
                                                  value={description}
                                                  onBlur={() => onBlurInputText('description')}
                                                  maxLength={501}>
                                         </textarea>

                                        <span
                                            className='d-flex justify-content-between theme-text-error theme-inputBox-text-sm'
                                            style={{paddingLeft: '15px', minHeight: '19px'}}>
                                        <span>{descriptionError}</span>
                                        </span>
                                    </div>
                                </div>
                                {/*<button className="button-style w-100" onClick={() => {*/}
                                {/*    handleSubmit()*/}
                                {/*}}>*/}
                                {/*    Submit*/}
                                {/*</button>*/}
                                <input type={'button'}
                                       className="button-style w-100"
                                       value={'Submit'}
                                       onClick={() => {
                                           handleSubmit()
                                       }}>

                                </input>
                            </div>
                            <div className="col-4 ">
                                <div className="panel px-5 py-3 h-100">
                                    <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                                        Let’s Talk
                                    </h2>
                                    <div className="pb-60">
                                        <img src={Email}
                                             height={56}
                                             width={85}/>
                                        <h3 className="theme-font-content-md">
                                            E-Mail
                                        </h3>
                                        <a style={{textDecoration: 'none'}}
                                           href={"mailto:" + 'info@deccanlogic.com'}>info@deccanlogic.com </a>
                                    </div>
                                    <div>
                                        <img src={Phone}
                                             height={56}
                                             width={56}/>
                                        <h3 className="theme-font-content-md">
                                            Phone
                                        </h3>
                                        <a style={{textDecoration: 'none'}}
                                           href={"tel:" + 919322213960}>+919322213960</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id={'location'} className="block-container">
                    <div className="row">
                        <div className="col-4">
                            <div className="d-flex">
                                <div>
                                    <img src={Location}
                                         height={56}
                                         width={41}/>
                                </div>
                                <div className="ps-3">
                                    <h3 className="theme-font-content-md">
                                        Address
                                    </h3>
                                    <h4 className="theme-font-content">
                                        Office # 05, 4ᵗʰ Floor, City Vista - B, Fountain Road, Kharadi, Pune-411014,
                                        Maharashtra, India.
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-8 ">
                            <div className="map-canvas pt-50 md-pt-80 w-100">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.2768035138283!2d73.94211117612926!3d18.561555367919283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c3dcc4f0e6f7%3A0xa7058ccb94fa7be0!2sDeccanlogic%20Private%20Limited!5e0!3m2!1sen!2sin!4v1694422290844!5m2!1sen!2sin"
                                    width="600"
                                    height="450"
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"/>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    };

    return (
        <div className='w-100 bg-white' style={{caretColor:"transparent"}}>
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

ContactUsView.propTypes = {};

const mapStateToProps = state => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
    isMDView: state.appState.deviceInfo.isMDView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(ContactUsView));
