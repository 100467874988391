import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Footer from "../core/layout/footer/footer";
import './home.css';
import OurService from "../assets/img/DL Homepage/campaign.jpg";
import SoftwareDevelopment from "../assets/img/DL Homepage/customSoftwareDevelopment.jpg";
import TechnologyPartnershipDesktop from "../assets/img/DL Homepage/technologyPartnership.jpg";
import TechnologyPartnership from "../assets/img/DL Homepage/tech mobile.jpg";
import CuttingEdge from "../assets/img/DL Homepage/cuttingEdge.png";
import IPassets from "../assets/img/DL Homepage/ipAssets.png";
import DeepTechnology from "../assets/img/DL Homepage/deepTechnology .png";
import Flexibility from "../assets/img/DL Homepage/executionFlexibility .png";
import {Link} from "react-router-dom";
import appTheme from "../assets/appTheme";


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

const Home = (props) => {
    const {isMobileView, isLGView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section className='block-container padding-block-top padding-block-bottom home-banner-bg'
                             id={'homepage-banner_mobile'}>
                        <div>
                            <h1 className="theme-font-page-title theme-font-bold"
                                style={{paddingBottom: 60}}>
                                Bring Innovative Ideas <br/>
                                To Reality <span style={{color: '#1671DD'}}> Together </span>
                            </h1>
                            <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-60">
                                We're a tech company partnering with startups and businesses to <br/>
                                turn forward-thinking ideas into hyper-growth solutions.
                            </h4>
                            <Link to={"/contact-us"}>
                                <button className="button-style">
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </section>

                    <div style={{height: 70}}/>

                    <section id={'dl-service_mobile'} style={{backgroundColor: '#F3F8FD'}}>
                        <div className="d-flex">
                            <div className="p-3 theme-font-section-title theme-font-semiBold "
                                 style={{
                                     color: '#FFFFFF',
                                     background: 'linear-gradient(180deg, #1670DB 0%, #092A7B 100%)',
                                 }}>
                                Our Services
                            </div>
                        </div>
                        <div className="block-container linear-gradient-border text-start">
                            <h2 className="theme-font-section-title theme-font-bold"
                                style={{paddingTop: 60, paddingBottom: 30}}>
                                Campaign Management for SMBs
                            </h2>
                            <h4 className="theme-font-content-md theme-font-light pb-60">
                                Discover SCuiDO, our revolutionary end-to-end campaign management multichannel
                                <b className="px-1">Software-as-a-Service </b>(SaaS) platform.
                            </h4>
                            <div>
                                <h2 className="theme-font-section-header theme-font-semiBold  pb-3">
                                    Enterprise-grade Customer Data Platform
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-60">
                                    Empowers you with boundless possibilities for crafting intricate customer segments
                                    like never before.
                                </h4>
                            </div>
                            <div>
                                <h2 className="theme-font-section-header theme-font-semiBold pb-3">
                                    Empower your marketing/sales teams
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height  pb-60">
                                    Break away from costly and time-consuming data ingestion, ETLs, analytics
                                    implementations and unlock a world of productivity, collaboration, and growth.
                                </h4>
                            </div>
                            <div>
                                <h2 className="theme-font-section-header theme-font-semiBold pb-3">
                                    Flexible Payment Model
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-60">
                                    Pay-as-you-go pricing, ensuring you only pay for what you need when you need it.
                                </h4>
                            </div>
                            <div className="pb-40">
                                <a href={'https://scuido.com/'}
                                target={'_blank'}>
                                    <button className="button-style">
                                        Explore SCuiDO
                                    </button>
                                </a>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12" style={{paddingBottom: 70}}>
                                    <img alt={''}
                                         src={OurService}
                                         height={384}
                                         className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'dl-technology-partnership_mobile'}>
                        <div style={{backgroundColor: '#F3F8FD'}}
                             className="block-container padding-block-top padding-block-bottom linear-gradient-border text-start">
                            <h2 className="theme-font-section-title pb-40 theme-font-bold">
                                Technology Partnership
                            </h2>
                            <h4 className="theme-font-content-md theme-font-light  pb-60 text-start">
                                We offer comprehensive end-to-end technology partnerships that allow entrepreneurs to
                                focus wholeheartedly on solving their core business challenges.
                            </h4>
                            <div className="text-start">
                                <h2 className="theme-font-section-header theme-font-semiBold  pb-3">
                                    End-to-End Solutions
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-60">
                                    Comprehensive support from inception to implementation, turn your vision into
                                    reality.
                                </h4>
                            </div>
                            <div className="text-start">
                                <h2 className="theme-font-section-header theme-font-semiBold pb-3">
                                    Reliable and Trustworthy
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height  pb-60">
                                    DeccanLogic stands as a dependable and trustworthy ally throughout the journey.
                                </h4>
                            </div>
                            <div className="text-start">
                                <h2 className="theme-font-section-header theme-font-semiBold pb-3">
                                    Tech Expertise
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-60">
                                    Leverage DeccanLogic's expertise for cutting-edge technology and innovative
                                    solutions.
                                </h4>
                            </div>
                            <div className="pb-40 text-start">
                                <Link to={'/services/technology-partnership'}>
                                    <button className="button-style">
                                        Read More
                                    </button>
                                </Link>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-12">
                                    <img alt={''}
                                         className='img-fluid'
                                         src={TechnologyPartnership}/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'software-development-services_mobile'}>
                        <div className="block-container padding-block-top padding-block-bottom"
                             style={{backgroundColor: '#F3F8FD'}}>
                            <div className="row">
                                <div className="col-12">
                                    <h2 className="theme-font-section-title pb-40 theme-font-bold">
                                        Custom Software Development
                                    </h2>
                                    <h4 className="theme-font-content-md theme-font-light pb-60 text-start">
                                        We understand that every business is unique, and that's why we offer bespoke
                                        software development services.
                                    </h4>
                                    <div className="text-start">
                                        <h2 className="theme-font-section-header theme-font-semiBold  pb-3">
                                            Flexibility and Customization
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-60">
                                            Comprehensive support from inception to implementation, turn your vision
                                            into reality.
                                        </h4>
                                    </div>
                                    <div className="text-start">
                                        <h2 className="theme-font-section-header theme-font-semiBold pb-3">
                                            Tech Expertise
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-60">
                                            Tap into our technical proficiency to transform your concept into reality
                                            using cutting-edge technological advancements.
                                        </h4>
                                    </div>
                                    <div className="pb-40 text-start">
                                        <Link to={'/services/custom-software-development'}>
                                            <button className="button-style">
                                                Read More
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <img alt={''}
                                         src={SoftwareDevelopment}
                                         height={384}
                                         className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'our-capabilities_mobile'}>
                        <div className="block-container padding-block-top padding-block-bottom">
                            <div style={{paddingBottom: 60}}>
                                <h1 className="theme-font-section-title text-start theme-font-bold">
                                    Our capabilities
                                </h1>
                                <h2 className="theme-font-content-md pt-40 pb-40 theme-font-normal">
                                    <i className="fa-solid fa-quote-left pe-1" style={{color:'var(--primary)'}}/>
                                    Ideas are easy. Implementation is hard.
                                    <i className="fa-solid fa-quote-right px-1" style={{color:'var(--primary)'}}/>
                                    - Guy Kawasaki
                                </h2>
                                <h4 className="theme-font-content-md theme-font-light">
                                    Its all about execution.
                                    <div className="py-3 mb-0 ">
                                        <div className="d-flex">
                                            <i className="fa-brands fa-facebook-f me-1 mt-2"
                                               style={{height: 25, width: 25, color: "#c6c8ca"}}/>
                                            <h3 className="theme-font-content-md theme-font-light">
                                                Facebook was not the first social network
                                            </h3>
                                        </div>
                                        <div className="d-flex">
                                            <i className="fa-solid fa-mobile-screen me-1 mt-2"
                                               style={{height: 25, width: 25, color: "#c6c8ca"}}/>
                                            <h3 className="theme-font-content-md theme-font-light">iPhone was not the
                                                first smartphone
                                            </h3>
                                        </div>
                                        <div className="d-flex">
                                            <i className="fa-brands fa-google me-1 mt-2"
                                               style={{height: 25, width: 25, color: "#c6c8ca"}}/>
                                            <h3 className="theme-font-content-md theme-font-light">Google was not the
                                                first search engine
                                            </h3>
                                        </div>
                                    </div>
                                    Though the ideas were outstanding, it is coupled with superior technology
                                    implementation.
                                    With our vast experience, we bring this flawless execution while bringing ideas to
                                    reality.
                                </h4>
                            </div>
                            <div className="d-flex flex-column pb-60" style={{gap: 60,}}>
                                <div className="d-flex flex-column our-capabilities-panel"
                                     style={{gap: 20}}>
                                    <img alt={''}
                                         src={CuttingEdge}
                                         height={60}
                                         width={60}
                                         className="img-fluid"/>
                                    <h2 className="theme-font-section-header theme-font-semiBold">
                                        Cutting-edge Technologies
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height  text-start">
                                        We have proven experience in developing high performing solutions in
                                        FinTech,
                                        data analytics, multi-device platforms, cloud, AI and ML.
                                    </h4>
                                </div>
                                <div className="d-flex flex-column our-capabilities-panel"
                                     style={{gap: 20}}>
                                    <img alt={''}
                                         src={Flexibility}
                                         height={60}
                                         width={60}
                                         className="img-fluid"/>
                                    <h2 className="theme-font-section-header theme-font-semiBold">
                                        Execution flexibility
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start">
                                        Change is the only constant in business life. Our proven delivery model is
                                        carefully designed to embrace changing business needs.
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex flex-column" style={{gap: 60}}>
                                <div className="d-flex flex-column text-start our-capabilities-panel"
                                     style={{gap: 20}}>
                                    <img alt={''}
                                         src={IPassets}
                                         height={60}
                                         width={60}
                                         className="img-fluid"/>
                                    <h2 className="theme-font-section-header theme-font-semiBold">
                                        IP assets
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start">
                                        We generate rich set of high quality IP assets that helps customers with
                                        significantly reduced go-to-market time at a fraction of cost.
                                    </h4>
                                </div>
                                <div className="d-flex flex-column our-capabilities-panel"
                                     style={{gap: 20}}>
                                    <img alt={''}
                                         src={DeepTechnology}
                                         height={60}
                                         width={60}
                                         className="img-fluid"/>
                                    <h2 className="theme-font-section-header theme-font-semiBold">
                                        Deep technology expertise
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height ">
                                        Both CEO and CTO are highly experienced professionals having worked at
                                        global
                                        corporates as well as startups with combined experience of more than 50
                                        years.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id='value-proposition_mobile'>
                        <div className="value-proposition-bg block-container padding-block-top padding-block-bottom">
                            <h1 className="theme-font-section-title theme-font-bold">
                                Value Proposition
                            </h1>
                            <h4 className="theme-font-content-md  theme-font-light  pt-40 pb-40">
                                We being partner with shared risks and rewards, we strive to make your business vision a
                                grand success.
                            </h4>
                            <div className="d-flex flex-column"
                                 style={{gap: 40}}>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">Fast</h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Home-grown IT assets to reduces go-to-market cycle dramatically.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">Flexible</h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Stretching the limits of IT architecture for the highest possible
                                            flexibility.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">Future-Proof</h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Latest cutting-edge technology that is ready to grow with your business
                                            growth.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{flex: 1}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">Cost
                                            Effective</h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                            Significantly lower cost of development as lean setup combined with
                                            partnership model.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'contact-us'}
                             className="linear-gradient-background padding-block-bottom padding-block-top text-center px-5">
                        <h2 className="theme-font-section-title text-center theme-font-semiBold pb-60 theme-text-white">
                            Ready to learn more about our services?
                        </h2>
                        <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-60 theme-text-white">
                            We are here to help you succeed. Get in touch with us to explore our offerings.
                        </h4>
                        <Link to={"/contact-us"}>
                            <button className="button-style-white ">
                                Contact Us
                            </button>
                        </Link>
                    </section>
                </>
            )
        }

        return (
            <div>
                <section className='block-container padding-block-top padding-block-bottom home-banner-bg'
                         id={'homepage-banner-desktopView'}>
                    <div>
                        <h1 className="banner-text pb-50 pt-5 theme-font-bold">
                            Bring Innovative Ideas <br/>
                            To Reality <span style={{color: '#1671DD'}}> Together </span>
                        </h1>
                        <div>
                            <h4 className="pb-50 theme-font-section-sub-header theme-font-light "
                                style={{lineHeight: '150%'}}>
                                We're a tech company partnering with startups and businesses to <br/>
                                turn forward-thinking ideas into hyper-growth solutions.
                            </h4>
                        </div>
                        <div className="pb-40">
                            <Link to={"/contact-us"}>
                                <button className="button-style">
                                    Contact Us
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>

                <section id={'dl-services'}>
                    <div className="d-flex" style={{backgroundColor: '#F3F8FD'}}>
                        <div className="theme-font-section-title padding-block-left padding-block-right  theme-font-semiBold"
                            style={{
                                color: '#FFFFFF',
                                background: 'linear-gradient(180deg, #1670DB 0%, #092A7B 100%)',
                                lineHeight: 1,
                                paddingTop: 68,
                                paddingBottom: 68
                            }}>
                            Our Services
                        </div>
                    </div>
                    <div className="padding-block-left padding-block-right"
                         style={{backgroundColor: '#F3F8FD'}}>
                        <div className="row">
                            <div className="col-7 d-flex align-items-center">
                                <div>
                                    <h2 className="theme-font-section-title pt-40 pb-40 theme-font-semiBold">
                                        Campaign Management for SMBs
                                    </h2>
                                    <h4 className="theme-font-content-md theme-font-light ">
                                        Discover SCuiDO, our revolutionary end-to-end campaign management
                                        multichannel
                                        <b className="px-1">Software-as-a-Service</b> (SaaS) platform.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-5 d-flex justify-content-end align-items-center">
                                <div className="pt-40">
                                    <img alt={''}
                                         src={OurService}
                                         height={319}
                                         className="img-fluid"
                                         style={{paddingLeft: 61}}/>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-60" style={{paddingTop: 60}}>
                            <div className="col-4">
                                <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4 linear-gradient-border-top h-100 pe-2">
                                    Enterprise-grade Customer Data Platform
                                </h2>
                            </div>
                            <div className="col-4">
                                <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4 linear-gradient-border-top h-100 pe-2">
                                    Empower your marketing/sales teams
                                </h2>
                            </div>
                            <div className="col-4">
                                <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4 h-100 ">
                                    Flexible Payment Model
                                </h2>
                            </div>
                            <div className="col-4">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-2 linear-gradient-border-bottom h-100 pb-40">
                                    Empowers you with boundless possibilities for crafting intricate customer segments
                                    like never before.
                                </h4>
                            </div>
                            <div className="col-4 ">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-2 linear-gradient-border-bottom h-100 pb-40">
                                    Break away from costly and time-consuming data ingestion, ETLs, analytics
                                    implementations and unlock a world of productivity, collaboration, and growth.
                                </h4>
                            </div>
                            <div className="col-4">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start h-100 pb-40">
                                    Pay-as-you-go pricing, ensuring you only pay for what you need when you need it.
                                </h4>
                            </div>
                        </div>

                        <div className="padding-block-bottom text-center">
                            <a href={"https://scuido.com/"}
                               target={'_blank'}>
                                <button className="button-style">
                                    Explore SCuiDO
                                </button>
                            </a>
                        </div>
                    </div>
                </section>

                <section id={'technology-partnership'}>
                    <div style={{backgroundColor: '#F3F8FD'}}
                         className="block-container">
                        <div className="bg-white padding-block-top padding-block-bottom px-5">
                            <div className="row">
                                <div className="col-5 d-flex justify-content-start align-items-center">
                                    <div className="">
                                        <img alt={''}
                                             src={TechnologyPartnershipDesktop}
                                             style={{paddingRight: 61}}
                                             height={319}
                                             className="img-fluid"
                                        />
                                    </div>
                                </div>
                                <div className="col-7 d-flex align-items-center">
                                    <div>
                                        <h2 className="theme-font-section-title pb-40 theme-font-semiBold">
                                            Technology Partnership
                                        </h2>
                                        <h4 className="theme-font-content-md theme-font-light  pb-40">
                                            We offer comprehensive end-to-end technology partnerships that allow
                                            entrepreneurs to focus wholeheartedly on solving their core business
                                            challenges.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row pb-60" style={{paddingTop: 60}}>
                                <div className="col-4">
                                    <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4 linear-gradient-border-top h-100 pe-2">
                                        End-to-End Solutions
                                    </h2>
                                </div>
                                <div className="col-4">
                                    <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4 linear-gradient-border-top h-100 pe-2">
                                        Reliable and Trustworthy
                                    </h2>
                                </div>
                                <div className="col-4">
                                    <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4 h-100">
                                        Tech Expertise
                                    </h2>
                                </div>
                                <div className="col-4">
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-2 linear-gradient-border-bottom h-100 pb-40">
                                        Comprehensive support from inception to implementation, turn your vision into
                                        reality.
                                    </h4>
                                </div>
                                <div className="col-4 ">
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-2 linear-gradient-border-bottom h-100 pb-40">
                                        DeccanLogic stands as a dependable and trustworthy ally throughout the
                                        journey.
                                    </h4>
                                </div>
                                <div className="col-4">
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start h-100 pb-40">
                                        Leverage DeccanLogic's expertise for cutting-edge technology and innovative
                                        solutions.
                                    </h4>
                                </div>
                            </div>
                            <div className="text-center">
                                <Link to={'/services/technology-partnership'}>
                                    <button className="button-style">
                                        Read More
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'software-development-services'}>
                    <div className="block-container padding-block-top padding-block-bottom"
                         style={{backgroundColor: '#F3F8FD'}}>
                        <div className="row">
                            <div className="col-7 d-flex align-items-center">
                                <div>
                                    <h2 className="theme-font-section-title pb-40 theme-font-semiBold">
                                        Custom Software Development
                                    </h2>
                                    <h4 className="theme-font-content-md theme-font-light  pb-40">
                                        We understand that every business is unique, and that's why we offer bespoke
                                        software development services.
                                    </h4>
                                </div>
                            </div>
                            <div className="col-5 d-flex justify-content-end align-items-center">
                                <div>
                                    <img alt={''}
                                         src={SoftwareDevelopment}
                                         height={319}
                                         className="img-fluid"
                                         style={{paddingLeft: 61}}/>
                                </div>
                            </div>
                        </div>

                        <div className="row pb-60" style={{paddingTop: 60}}>
                            <div className="col-6">
                                <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4 pe-2 linear-gradient-border-top h-100">
                                    Flexibility and Customization
                                </h2>
                            </div>
                            <div className="col-6">
                                <h2 className="theme-font-section-header theme-font-semiBold text-start pt-3 pb-4  h-100">
                                    Tech Expertise
                                </h2>
                            </div>
                            <div className="col-6">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-2 linear-gradient-border-bottom h-100 pb-40">
                                    Software development at your style, comfort, and pace.
                                </h4>
                            </div>
                            <div className="col-6">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start h-100 pb-40">
                                    Tap into our technical proficiency to transform your concept into reality using
                                    cutting-edge technological advancements.
                                </h4>
                            </div>
                        </div>


                        <div className="text-center">
                            <Link to={'/services/custom-software-development'}>
                                <button className="button-style">
                                    Read More
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>

                <section id={'our-capabilities'}>
                    <div className="block-container padding-block-top padding-block-bottom">
                        <div className="row">
                            <div className="col-4 px-0 d-flex align-items-center justify-content-center">
                                <div>
                                <h1 className="theme-font-section-title theme-font-semiBold text-start">
                                    Our capabilities
                                </h1>
                                <h2 className="theme-font-content-md pt-40 pb-40 theme-font-normal">
                                    <i className="fa-solid fa-quote-left pe-1" style={{color:'var(--primary)'}}/>
                                    Ideas are easy. Implementation is hard.
                                    <i className="fa-solid fa-quote-right px-1" style={{color:'var(--primary)'}}/>
                                    - Guy Kawasaki
                                </h2>
                                <h3 className="theme-font-content-md  pb-40">
                                    <span style={{color:'#1671DD'}}> Its all about execution.  </span> Facebook was not the first social network, the iPhone was
                                    not the first smartphone, Google was not the first search engine. Though the ideas
                                    were outstanding, it is coupled with superior technology implementation.
                                </h3>
                                <h3 className="theme-font-content-md ">
                                    With our vast experience, we bring this
                                    <span className="px-1" style={{color:'var(--secondary)'}}> flawless execution  </span>
                                    while bringing
                                    <span className="px-1" style={{color:'var(--primary)'}}>ideas to reality. </span>
                                </h3>
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="d-flex ps-5" style={{gap:40}}>
                                    <div className="d-flex flex-column mt-5"  style={{flex:1, gap:40}}>
                                        <div className="our-capabilities-panel">
                                            <img alt={''}
                                                 src={CuttingEdge}
                                                 height={60}
                                                 width={60}
                                                 className="img-fluid"/>
                                            <h2 className="theme-font-section-header theme-font-semiBold"
                                                style={{paddingBottom: 20, paddingTop: 20}}>
                                                Cutting-edge Technologies
                                            </h2>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                                We have proven experience in developing high performing solutions in
                                                FinTech,
                                                data analytics, multi-device platforms, cloud, AI and ML.
                                            </h4>
                                        </div>
                                        <div className="our-capabilities-panel">
                                            <img alt={''}
                                                 src={IPassets}
                                                 height={60}
                                                 width={60}
                                                 className="img-fluid"/>
                                            <h2 className="theme-font-section-header theme-font-semiBold"
                                                style={{paddingBottom: 20, paddingTop: 20}}>
                                                IP assets
                                            </h2>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height ">
                                                We generate rich set of high quality IP assets that helps customers with
                                                significantly reduced go-to-market time at a fraction of cost.
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column" style={{flex:1, gap:40}}>
                                        <div className="our-capabilities-panel">
                                            <img alt={''}
                                                 src={Flexibility}
                                                 height={60}
                                                 width={60}
                                                 className="img-fluid"/>
                                            <h2 className="theme-font-section-header theme-font-semiBold"
                                                style={{paddingBottom: 20, paddingTop: 20}}>
                                                Execution flexibility
                                            </h2>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                                Change is the only constant in business life. Our proven delivery model is
                                                carefully designed to embrace changing business needs.
                                            </h4>
                                        </div>
                                        <div className="our-capabilities-panel">
                                            <img alt={''}
                                                 src={DeepTechnology}
                                                 height={60}
                                                 width={60}
                                                 className="img-fluid"/>
                                            <h2 className="theme-font-section-header theme-font-semiBold"
                                                style={{paddingBottom: 20, paddingTop: 20}}>
                                                Deep technology expertise
                                            </h2>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                                Both CEO and CTO are highly experienced professionals having worked at global
                                                corporates as well as startups with combined experience of more than 50 years.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section id='value-proposition'>
                    <div className="value-proposition-bg block-container padding-block-top padding-block-bottom">
                        <h1 className="theme-font-section-title text-center theme-font-semiBold">
                            Value Proposition
                        </h1>
                        <h4 className="theme-font-content-md theme-font-light text-center pt-40 pb-40">
                            We being partner with shared risks and rewards, we strive to make your business vision a
                            grand success.
                        </h4>
                        <div className="d-flex" style={{gap: 50}}>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className=" top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4"
                                        style={{minHeight: isLGView ? 115 : ''}}>Fast</h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Home-grown IT assets to reduces go-to-market cycle dramatically.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className="top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4"
                                        style={{minHeight: isLGView ? 115 : ''}}>Flexible</h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Stretching the limits of IT architecture for the highest possible
                                        flexibility. </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className=" top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4"
                                        style={{minHeight: isLGView ? 115 : ''}}>Future-Proof</h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Latest cutting-edge technology that is ready to grow with your business
                                        growth.
                                    </h4>
                                </div>
                            </div>
                            <div className="panel"
                                 style={{flex: 1}}>
                                <div className="top-strip-blue"/>
                                <div className="px-3">
                                    <h2 className="theme-font-section-header theme-font-semiBold py-4"
                                        style={{minHeight: isLGView ? 115 : ''}}>
                                        Cost Effective
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-40">
                                        Significantly lower cost of development as lean setup combined with partnership
                                        model.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'contact-us'}
                         className="linear-gradient-background padding-block-bottom padding-block-top text-center">
                    <h2 className="theme-font-section-title text-center theme-font-semiBold pb-60 theme-text-white">
                        Ready to learn more about our services?
                    </h2>
                    <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-60 theme-text-white">
                        We are here to help you succeed. Get in touch with us to explore our offerings.
                    </h4>
                    <Link to={"/contact-us"}>
                        <button className="button-style-white ">
                            Contact Us
                        </button>
                    </Link>
                </section>
            </div>
        )
    };

    return (
        <div className="w-100">
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

Home.propTypes = {};

Home.defaultProps = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(Home));
