import {httpGet, httpPost} from "../../utils/httpHelper";
import {showToastMessage} from "../../core/actions/appActions";
import store from "../../../src/store";

export const getTicketFor = (accountId) => {
    let url = '/service/provisioning/support/ticketFor';

    return httpGet(url, accountId)
        .then(res => {
            return res;
        })
};

export const raisePreLoginTicket = (data, accountId) => {
    let url = '/service/provisioning/support/preLoginTicketRaised';
    return httpPost(url,data,accountId)
        .then((res) => {
            return res
        })
};

export const contactUs =(data)=> {
    let url = "https://www.deccanlogic.com/api/content/contactUs";

    return fetch(url, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    }).then(res => {
        if(res.status === 200 || res.status === 204){
            console.log("Request complete! response:", res);
            return {data: res , success: true}
        }
    })
    .catch((error)=>{
        console.log("In catch", error);
        return {error: error, success: false}
    })
}