import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';


class Loader extends Component {
  render() {
    const {type,isVisible,systemError, appLoader, color} = this.props;

    if(systemError.isVisible){
      return  null;
    }

    if(isVisible || appLoader.isVisible) {
      if(type === 'inline') {
        return (
            <div>
              {/*<CircularProgress color={color}*/}
              {/*                  thickness={thickness}*/}
              {/*                  style={{*/}
              {/*                    ...customStyle,*/}
              {/*                  }}*/}
              {/*                  disableShrink={true}*/}
              {/*                  variant={"indeterminate"}*/}
              {/*/>*/}
              <div className='d-flex align-items-center justify-content-center'>
                <div className='loader'/>
              </div>
            </div>
        );
      }

      return (
          <div className='dl-app-loader-container'>
            <div className='d-flex align-items-center justify-content-center'>
              <div className='loader' color={color}
                    style={{
                        height: '50px',
                        width: '50px'
                    }}/>
            </div>
            {/*<CircularProgress color={color}*/}
            {/*                  thickness={6}*/}
            {/*                  style={{*/}
            {/*                      width: '50px',*/}
            {/*                      height: '50px',*/}
            {/*                  }}*/}
            {/*                  disableShrink={true}*/}
            {/*                  variant={"indeterminate"}*/}
            {/*/>*/}
            {/*<div className="dl-app-loader-container">*/}
            {/*  <div className="dl-app-loader" />*/}
            {/*</div>*/}
          </div>
      );
    }
    return  null;
  }
}

Loader.propTypes = {
  type: PropTypes.oneOf([
    'screen', // to show the loader on complete screen
    'inline', // to show the loader on current position
    'splash', // to show the logo on complete screen
  ]),
  isVisible: PropTypes.bool,
  color: PropTypes.string,
  customStyle: PropTypes.object,
  thickness:PropTypes.number,
};

Loader.defaultProps = {
  type: 'screen',
  isVisible: false,
  color: 'primary',
  customStyle: {},
  thickness:7
};

const mapStateToProps = (state) => ({
  appLoader: state.appState.appLoader,
  systemError: state.appState.systemError
});

export default connect(mapStateToProps, {})(Loader);
