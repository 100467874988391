import {
    setAppLoaderData,
    setCurrentPathNameData, setPrevLocationData,
    showSystemErrorBlockData, showToastMessageData,

} from "../reducers/appReducer";
import {httpGet} from "../../utils/httpHelper";

export const showToastMessage = (type, message) => (dispatch) => {
    // type should be one of 'info', 'success', 'warning', 'danger'
    let types = ['info', 'success', 'warning', 'danger'];
    let newType = type;
    if (types.includes(type)) {
        //
    } else {
        newType = 'danger'
    }
    dispatch(
        showToastMessageData({
            type: newType,
            message: message
        })
    )
};


export const setAppLoader = (isVisible) => (dispatch) => {
    dispatch(setAppLoaderData({
        type: "screen",
        isVisible: isVisible
    }))
};


export const showSystemErrorBlock = (errorObject) => dispatch => {
    dispatch(setAppLoader(false));
    dispatch(showSystemErrorBlockData(errorObject));
};


export const setPrevLocation = (location) => dispatch => {
    dispatch(setPrevLocationData(location))
};

export const setCurrentPathname = (pathname) => dispatch => {
    dispatch(setCurrentPathNameData(pathname))
};

export const getSeoData = () => {
    let url = '/service/operations/seo/prelogin/all?&skipCount=0&limitCount=999';

    return httpGet(url)
        .then((res) => {
            return res;
        })
};
