export const seoMetaData = [
    {
        "id": 1,
        "url": "https://deccanlogic.com/",
        "title": "DeccanLogic - Ideas to Reality",
        "SEOAttributes": {
            "name": [
                {SEOMasterKey: "description", value: "Technology company that helps businesses and startups in conceptualization and implementation of future ready ideas"},
                {SEOMasterKey: "keywords", value: ["Tech Company", "Startups", "Innovative Ideas", "Hyper-Growth Solutions", "End-to-End Solutions", "Technology Partnership", "Custom Software Development", "Bespoke Software Development", "Data Analytics", "Multi-device Platforms", "Cloud Solutions", "AI Solutions", "Pune", "Maharashtra", "India", "Offshore"]}
            ],
            "property": [
                {SEOMasterKey: "openGraphTitle", value: "DeccanLogic - Ideas to Reality"},
                {SEOMasterKey: "openGraphDescription", value: "Technology company that helps businesses and startups in conceptualization and implementation of future ready ideas"}
            ]
        }
    },
    {
        "id": 2,
        "url": "https://www.deccanlogic.com/contact-us",
        "title": "DeccanLogic - Contact-Us",
        "SEOAttributes": {
            "name": [
                {SEOMasterKey: "description", value: "Get in touch with us to understand more about our service offerings"},
                {SEOMasterKey: "keywords", value: ["unicom", "contact-us"]}
            ],
            "property": [
                {SEOMasterKey: "openGraphTitle", value: "DeccanLogic - Contact-Us"}
            ]
        }
    },
    {
        "id": 3,
        "url": "https://www.deccanlogic.com/services/technology-partnership",
        "title": "Tech Partnership Services - DeccanLogic",
        "SEOAttributes": {
            "name": [
                {SEOMasterKey: "description", value: "Explore our service offering as a technology partner in your product development roadmap"},
                {SEOMasterKey: "keywords", value: ["Tech Company", "Startups", "Innovative Ideas", "Hyper-Growth Solutions", "End-to-End Solutions", "Technology Partnership","Pune", "Maharashtra", "India", "Offshore"]}
            ],
            "property": [
                {SEOMasterKey: "openGraphTitle", value: "Tech Partnership Services - DeccanLogic"}
            ]
        }
    },
    {
        "id": 4,
        "url": "https://www.deccanlogic.com/services/custom-software-development",
        "title": "Custom Software Development - DeccanLogic",
        "SEOAttributes": {
            "name": [
                {SEOMasterKey: "description", value: "Explore our custom software development services in app development"},
                {SEOMasterKey: "keywords", value: ["Tech Company", "Startups", "Innovative Ideas", "Hyper-Growth Solutions", "End-to-End Solutions", "Custom Software Development", "Web App Development", "Mobile App Development", "Cloud Solutions", "AI Solutions", "LLM Solutions", "Pune", "Maharashtra", "India", "Offshore"]}
            ],
            "property": [
                {SEOMasterKey: "openGraphTitle", value: "Custom Software Development - DeccanLogic"}
            ]
        }
    },
    {
        "id": 5,
        "url": "https://www.deccanlogic.com/about-us",
        "title": "DeccanLogic - About Us",
        "SEOAttributes": {
            "name": [
                {SEOMasterKey: "description", value: "Founded in 2018, DeccanLogic is driven by a shared vision to make enterprise-quality tech services accessible to all."},
                {SEOMasterKey: "keywords", value: ["Tech Company", "Startups", "Innovative Ideas", "Pune", "Maharashtra", "India", "Offshore"]}
            ],
            "property": [
                {SEOMasterKey: "openGraphTitle", value: "DeccanLogic - About Us"}
            ]
        }
    }
];