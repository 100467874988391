import {APP_CONST} from '../AppConstants';


export const languages = {
  defaultActiveLanguage: APP_CONST.LANG_ENG,
  languageOptions: [
    {
      id: APP_CONST.LANG_ENG,
      transKey: 'English',
    },
    {
      id: APP_CONST.LANG_HIN,
      transKey: 'Hindi',
    },
  ],
};
