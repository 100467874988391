import React, {useEffect} from 'react';
import Innovation from "../assets/img/Technology partnership/innovation.png";
import Speed from "../assets/img/Technology partnership/speed.png";
import Reliability from "../assets/img/Technology partnership/reliability.png";
import IntegrityTransparency from "../assets/img/Technology partnership/integrity.png";
import Flexibility from "../assets/img/Technology partnership/flexibility.png";
import InnovationMobile from "../assets/img/Technology partnership/innovation mobile.png";
import SpeedMobile from "../assets/img/Technology partnership/speed mobile.png";
import ReliabilityMobile from "../assets/img/Technology partnership/reliability mobile.png";
import IntegrityTransparencyMobile from "../assets/img/Technology partnership/integrity mobile.png";
import FlexibilityMobile from "../assets/img/Technology partnership/flexibility mobile.png";
import CapEx from "../assets/img/Technology partnership/lowerCapEx.png";
import CompetitiveAdvantage from "../assets/img/Technology partnership/competitiveAdvantage.png";
import Focus from "../assets/img/Technology partnership/focus.png";
import Footer from "../core/layout/footer/footer";
import "../technologyPartnership/technologyPartnership.css"
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";

function TechnologyPartnership(props) {
    const {isMobileView, isLGView, isXSView, isSMView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderUI = () => {
        if (isMobileView) {
            return (
                <>
                    <section className='technology-banner-bg d-flex justify-content-center'
                             id={'technology-banner-mobile'}>
                        <div className="block-container py-3">
                            <h1 className="text-center theme-font-page-title theme-font-bold">
                                Technology Partnership
                            </h1>
                        </div>
                    </section>

                    <section id={'product-development-mobile'}>
                        <div className="block-container padding-block-top padding-block-bottom">
                            <h2 className="theme-font-section-title theme-font-bold ">
                                Your Partner in Product Development
                            </h2>
                            <h3 className="theme-font-content-md theme-font-light pt-40 pb-40">
                                Why and When to Choose an External Tech Partner for Your Product
                            </h3>
                            <div className="d-flex flex-column"
                                 style={{gap: 40}}>
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Expertise and Specialization
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-3">
                                            When your team lacks specific technical skills or experience, an external
                                            partner brings specialized expertise to ensure a robust and high-quality
                                            MVP.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Resource Optimization
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-3">
                                            Partnering externally lets you focus on core business functions, while
                                            experts handle the intricacies of MVP development, optimizing resource
                                            allocation.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Innovation
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-3">
                                            Leveraging external perspectives infuses fresh ideas and innovative
                                            approaches, enhancing the uniqueness and appeal of your MVP.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Cost-Efficiency
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-3">
                                            Collaborating with an external partner can often be more cost-effective than
                                            building an in-house team and infrastructure from scratch.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Risk Mitigation
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-3">
                                            Startups and businesses can reduce risks by leveraging the partner's proven
                                            track record and experience, minimizing potential pitfalls.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Time-to-Market Acceleration
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-3">
                                            For time-sensitive projects, an external partner can expedite development,
                                            ensuring a quicker launch and a competitive edge in the market.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 200}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Scalability and Expansion
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-3">
                                            When scaling your business, an external partner can facilitate rapid
                                            expansion by quickly developing and launching new MVP iterations.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'technology-partnership-mobile'}>
                        <div style={{backgroundColor: '#EEF2FA'}}
                             className="block-container padding-block-top padding-block-bottom">
                            <h2 className="theme-font-section-title theme-font-bold pb-40">
                                Why <span style={{color:'var(--secondary)'}}> Choose Us </span> as Your Tech Partner?
                            </h2>
                            <div className="linear-gradient-border text-start" >
                                <img src={InnovationMobile}
                                     height={60}
                                     width={60}/>
                                <h2 className="theme-font-section-header theme-font-semiBold"
                                    style={{paddingTop: 20, paddingBottom: 20}}>
                                    Innovation
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height"
                                    style={{paddingBottom: 30}}>
                                    Our creative approach drives inventive solutions tailored to your unique product
                                    concept.
                                </h4>
                            </div>
                            <div className="linear-gradient-border text-start"
                                 style={{paddingTop: 30}}>
                                <img src={SpeedMobile}
                                     height={60}
                                     width={60}/>
                                <h2 className="theme-font-section-header theme-font-semiBold pb-40"
                                    style={{paddingTop: 20, paddingBottom: 20}}>
                                    Speed
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height"
                                    style={{paddingBottom: 30}}>
                                    That Accelerates Your Vision: Our agile development ensures swift MVP
                                    realization, giving you a competitive advantage.
                                </h4>
                            </div>
                            <div className="linear-gradient-border text-start"
                                 style={{paddingTop: 30}}>
                                <img src={ReliabilityMobile}
                                     height={60}
                                     width={60}/>
                                <h2 className="theme-font-section-header theme-font-semiBold pb-40"
                                    style={{paddingTop: 20, paddingBottom: 20}}>
                                    Reliability
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height"
                                    style={{paddingBottom: 30}}>
                                    Our proven track record reflects a steadfast commitment to delivering results on
                                    time and with excellence.
                                </h4>
                            </div>
                            <div className="linear-gradient-border text-start"
                                 style={{paddingTop: 30}}>
                                <img src={IntegrityTransparencyMobile}
                                     height={60}
                                     width={60}/>
                                <h2 className="theme-font-section-header theme-font-semiBold pb-40"
                                    style={{paddingTop: 20, paddingBottom: 20}}>
                                    Integrity and Transparency
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height"
                                    style={{paddingBottom: 30}}>
                                    We prioritize open communication and ethical practices, fostering a trusting and
                                    collaborative partnership.
                                </h4>
                            </div>
                            <div className="text-start"
                                 style={{paddingTop: 30}}>
                                <img src={FlexibilityMobile}
                                     height={60}
                                     width={60}/>
                                <h2 className="theme-font-section-header theme-font-semiBold pb-40"
                                    style={{paddingTop: 20, paddingBottom: 20}}>
                                    Flexibility
                                </h2>
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                    Our adaptable mindset empowers us to flex with your changing needs, ensuring your
                                    MVP's success story.
                                </h4>
                            </div>
                        </div>
                    </section>

                    <section id={'contribute-areas-mobile'}>
                        <div className="padding-block-top padding-block-bottom block-container">
                            <h2 className="theme-font-section-title theme-font-bold pb-60">
                                Areas where we can contribute
                            </h2>
                            <div className="d-flex flex-column align-items-start">
                                <div className="linear-gradient-border">
                                    <h3 className="theme-font-section-header text-start">
                                        Build MVP
                                    </h3>
                                    <h4 className="theme-font-content text-start"
                                        style={{paddingTop: 20, paddingBottom: 30}}>
                                        Launch fast, gather feedback, and conquer.
                                    </h4>
                                </div>
                                <div className="linear-gradient-border"
                                     style={{paddingTop: 30}}>
                                    <h3 className="theme-font-section-header text-start">
                                        Build Add-on features
                                    </h3>
                                    <h4 className="theme-font-content text-start"
                                        style={{paddingTop: 20, paddingBottom: 30}}>
                                        Elevate, engage, succeed.
                                    </h4>
                                </div>
                                <div style={{paddingTop: 30}}>
                                    <h3 className="theme-font-section-header text-start">
                                        Scale the product
                                    </h3>
                                    <h4 className="theme-font-content text-start"
                                        style={{paddingTop: 20}}>
                                        Unleash exponential growth, free from IT constraints.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'partnership-benefits-mobile'}>
                        <div className="block-container padding-block-top padding-block-bottom"
                             style={{backgroundColor: '#EEF2FA'}}>
                            <h2 className="theme-font-section-title theme-font-bold pb-40">
                                Partnership Benefits
                            </h2>
                            <div className="d-flex pb-40">
                                <div className="bg-white px-3 panel">
                                    <div className="py-4">
                                        <div className="pb-20">
                                            <img src={CapEx}
                                                 height={60}
                                                 width={70}/>
                                        </div>
                                        <div>
                                            <h3 className="theme-font-section-header pb-20 text-start">
                                                Lower CapEx
                                            </h3>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start">
                                                Cash is king for businesses. With our engagement, our partners can
                                                lower
                                                their technology expenditure without compromising business growth
                                                plans.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex pb-40">
                                <div className="bg-white panel px-3 py-4">
                                    <div className="pb-20">
                                        <img src={CompetitiveAdvantage}
                                             height={60}
                                             width={60}/>
                                    </div>
                                    <div>
                                        <h3 className="theme-font-section-header pb-20 text-start">
                                            Competitive advantage
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start ">
                                            With our capabilities, our partners can bring their innovations to
                                            market at significantly faster pace and stay ahead of competition.
                                        </h4>
                                    </div>
                                </div>

                            </div>
                            <div className="d-flex ">
                                <div className="bg-white panel px-3 py-4">
                                    <div className="pb-20">
                                        <img src={Focus}
                                             height={60}
                                             width={70}/>
                                    </div>
                                    <div>
                                        <h3 className="theme-font-section-header pb-20 text-start">
                                            Focus on core competence
                                        </h3>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start ">
                                            We take end to end technological ownership. Hence business owners
                                            can
                                            focus on business problems rather than solving technological
                                            problems.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section id={'contact-us'}
                             className="linear-gradient-background padding-block-bottom padding-block-top text-center px-5">
                        <h2 className="theme-font-section-title text-center theme-font-semiBold pb-60 theme-text-white">
                            Ready to learn more about our services?
                        </h2>
                        <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-60 theme-text-white">
                            We are here to help you succeed. Get in touch with us to explore our offerings.
                        </h4>
                        <Link to={"/contact-us"}>
                        <button className="button-style-white ">
                            Contact Us
                        </button>
                        </Link>
                    </section>
                </>
            )
        }

        return (
            <div>
                <section id={'technology-banner-desktopView'}
                         className='technology-banner-bg'>
                    <h1 className="theme-font-page-title theme-font-bold padding-block-left">
                        Technology Partnership
                    </h1>
                </section>

                <section id={'product-development-desktop'}>
                    <div className="block-container padding-block-top padding-block-bottom">
                        <h2 className="theme-font-section-title theme-font-semiBold text-center">
                            Your Partner in Product Development
                        </h2>
                        <h3 className="text-center theme-font-content-md theme-font-light pt-40 pb-40">
                            Why and When to Choose an External Tech Partner for Your Product
                        </h3>
                        <div className="d-flex"
                             style={{gap: 20}}>
                            <div className="d-flex flex-column justify-content-center"
                                 style={{flex: 1, gap: 20}}>
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className="top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Time-to-Market Acceleration
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            For time-sensitive projects, an external partner can expedite development,
                                            ensuring a quicker launch and a competitive edge in the market.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Scalability and Expansion
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            When scaling your business, an external partner can facilitate rapid
                                            expansion by quickly developing and launching new MVP iterations.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column" style={{flex: 1, gap: 20}}>
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Expertise and Specialization
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            When your team lacks specific technical skills or experience, an external
                                            partner brings specialized expertise to ensure a robust and high-quality
                                            MVP.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Cost-Efficiency
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Collaborating with an external partner can often be more cost-effective than
                                            building an in-house team and infrastructure from scratch.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Risk Mitigation
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Startups and businesses can reduce risks by leveraging the partner's proven
                                            track record and experience, minimizing potential pitfalls.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-center"
                                 style={{flex: 1, gap: 20}}>
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Resource Optimization
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Partnering externally lets you focus on core business functions, while
                                            experts handle the intricacies of MVP development, optimizing resource
                                            allocation.
                                        </h4>
                                    </div>
                                </div>
                                <div className="panel"
                                     style={{minHeight: 220}}>
                                    <div className=" top-strip-blue"/>
                                    <div className="px-3">
                                        <h2 className="theme-font-section-header theme-font-semiBold py-4">
                                            Innovation
                                        </h2>
                                        <h4 className="theme-font-content theme-font-normal theme-content-line-height pb-2">
                                            Leveraging external perspectives infuses fresh ideas and innovative
                                            approaches, enhancing the uniqueness and appeal of your MVP.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'technology-partnership-desktop'}>
                    <div style={{backgroundColor: '#F3F8FD'}}
                         className="block-container padding-block-top padding-block-bottom">
                        <div className="bg-white panel">
                            <h2 className="theme-font-section-title theme-font-semiBold padding-block-top text-center pb-40">
                                Why <span style={{color:'var(--secondary)'}}> Choose Us </span> as Your Tech Partner?
                            </h2>
                            <div className="d-flex px-5 pb-40">
                                <div className="border-linear-gradient pe-5"
                                     style={{flex: 1}}>
                                    <img src={Innovation}
                                         height={60}
                                         width={60}/>
                                    <h2 className="theme-font-section-header theme-font-semiBold  pt-3 pb-4">
                                        Innovation
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height ">
                                        Our creative approach drives inventive solutions tailored to your unique product
                                        concept.
                                    </h4>
                                </div>
                                <div className="border-linear-gradient px-5" style={{flex: 1}}>
                                    <img src={Speed}
                                         height={60}
                                         width={60}/>
                                    <h2 className="theme-font-section-header theme-font-semiBold  pt-3 pb-4">
                                        Speed
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        That Accelerates Your Vision: Our agile development ensures swift MVP
                                        realization, giving you a competitive advantage.
                                    </h4>
                                </div>
                                <div className="px-5" style={{flex: 1}}>
                                    <img src={Reliability}
                                         height={60}
                                         width={60}/>
                                    <h2 className="theme-font-section-header theme-font-semiBold  pt-3 pb-4">
                                        Reliability
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        Our proven track record reflects a steadfast commitment to delivering results on
                                        time and with excellence.
                                    </h4>
                                </div>
                            </div>
                            <div className="d-flex block-container padding-block-bottom">
                                <div className="border-linear-gradient padding-block-left pe-5"
                                     style={{flex: 1}}>
                                    <img src={IntegrityTransparency}
                                         height={60}
                                         width={60}/>
                                    <h2 className="theme-font-section-header theme-font-semiBold  pt-3 pb-4"
                                        style={{paddingTop: 30}}>
                                        Integrity and Transparency
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        We prioritize open communication and ethical practices, fostering a trusting and
                                        collaborative partnership.
                                    </h4>
                                </div>
                                <div className="padding-block-right ps-5"
                                     style={{flex: 1}}>
                                    <img src={Flexibility}
                                         height={60}
                                         width={60}/>
                                    <h2 className="theme-font-section-header theme-font-semiBold  pt-3 pb-4"
                                        style={{paddingTop: 30}}>
                                        Flexibility
                                    </h2>
                                    <h4 className="theme-font-content theme-font-normal theme-content-line-height">
                                        Our adaptable mindset empowers us to flex with your changing needs, ensuring
                                        your MVP's success story.
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'contribute-areas-desktop'}>
                    <div className="padding-block-top padding-block-bottom block-container">
                        <h2 className="theme-font-section-title theme-font-semiBold text-center pb-40">
                            Areas where we can contribute
                        </h2>
                        <div className="row" >
                            <div className="col-4 ">
                                <h2 className="theme-font-section-header theme-font-semiBold text-center pb-4 linear-gradient-border-top h-100"
                                    style={{paddingTop:60}}>
                                    Build MVP
                                </h2>
                            </div>
                            <div className="col-4 ">
                                <h2 className="theme-font-section-header theme-font-semiBold text-center  pb-4 linear-gradient-border-top h-100"
                                    style={{paddingTop:60}}>
                                    Build Add-on features
                                </h2>
                            </div>
                            <div className="col-4 ">
                                <h2 className="theme-font-section-header theme-font-semiBold text-center  pb-4 h-100"
                                    style={{paddingTop:60}}>
                                    Scale the product
                                </h2>
                            </div>
                            <div className="col-4 ">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-center pb-60 linear-gradient-border-bottom h-100" >
                                    Launch fast, gather feedback, and conquer.
                                </h4>
                            </div>
                            <div className="col-4 ">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-center pb-60 linear-gradient-border-bottom h-100" >
                                    Elevate, engage, succeed.
                                </h4>
                            </div>
                            <div className="col-4 ">
                                <h4 className="theme-font-content theme-font-normal theme-content-line-height text-center pb-60 h-100" >
                                    Unleash exponential growth, free from IT constraints.
                                </h4>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'partnership-benefits-desktop'}>
                    <div className="block-container padding-block-top padding-block-bottom"
                         style={{backgroundColor: '#F3F8FD'}}>
                        <div className="text-center">
                            <h2 className="theme-font-section-title theme-font-semiBold pb-40">
                                Partnership Benefits
                            </h2>
                            <div className="d-flex pb-40">
                                <div className="bg-white panel"
                                     style={{flex: 0.8}}>
                                    <div className="d-flex align-items-center pb-40"
                                         style={{paddingTop: 30}}>
                                        <div className="px-4">
                                            <img src={CapEx}
                                                 height={60}
                                                 width={70}/>
                                        </div>
                                        <div>
                                            <h3 className="theme-font-section-header pb-3 text-start">
                                                Lower CapEx
                                            </h3>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-4">
                                                Cash is king for businesses. With our engagement, our partners can lower
                                                their technology expenditure without compromising business growth plans.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex: 0.4}}/>
                            </div>
                            <div className="d-flex pb-40">
                                <div style={{flex: 0.4}}/>
                                <div className="bg-white panel"
                                     style={{flex: 0.8}}>
                                    <div className="d-flex align-items-center  pb-40"
                                         style={{paddingTop: 30}}>
                                        <div className="px-4">
                                            <img src={CompetitiveAdvantage}
                                                 height={60}
                                                 width={60}/>
                                        </div>
                                        <div>
                                            <h3 className="theme-font-section-header pb-3 text-start">
                                                Competitive advantage
                                            </h3>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-4">
                                                With our capabilities, our partners can bring their innovations to
                                                market at significantly faster pace and stay ahead of competition.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="bg-white panel"
                                     style={{flex: 0.8}}>
                                    <div className="d-flex align-items-center  pb-40"
                                         style={{paddingTop: 30}}>
                                        <div className="px-4">
                                            <img src={Focus}
                                                 height={60}
                                                 width={70}/>
                                        </div>
                                        <div>
                                            <h3 className="theme-font-section-header pb-3 text-start">
                                                Focus on core competence
                                            </h3>
                                            <h4 className="theme-font-content theme-font-normal theme-content-line-height text-start pe-4">
                                                We take end to end technological ownership. Hence business owners can
                                                focus on business problems rather than solving technological problems.
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{flex: 0.4}}/>
                            </div>
                        </div>
                    </div>
                </section>

                <section id={'contact-us'}
                         className="linear-gradient-background padding-block-bottom padding-block-top text-center px-5">
                    <h2 className="theme-font-section-title text-center theme-font-semiBold pb-60 theme-text-white">
                        Ready to learn more about our services?
                    </h2>
                    <h4 className="theme-font-content-md theme-font-light theme-content-line-height pb-60 theme-text-white">
                        We are here to help you succeed. Get in touch with us to explore our offerings.
                    </h4>
                    <Link to={"/contact-us"}>
                    <button className="button-style-white ">
                        Contact Us
                    </button>
                    </Link>
                </section>
            </div>
        )
    };

    return (
        <div className="w-100">
            {renderUI()}
            <Footer {...props}/>
        </div>
    );
};

TechnologyPartnership.propTypes = {};

TechnologyPartnership.defaultProps = {};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    isSMView: state.appState.deviceInfo.isSMView,
});

export default connect(mapStateToProps, {})(withTranslation('translations')(TechnologyPartnership));
