import React, {useEffect, useState} from 'react';
import {isEmpty} from "../../utils/validations";
import {APP_CONST} from "../../core/AppConstants";
import {isValidEmail} from "../../utils/helper";
import ContactUsView from "./ContactUsView";
import {contactUs} from "./contactUsActions";
import store from "../../store";
import {showToastMessage} from "../../core/actions/appActions";

function ContactUs(props) {
    const [contactUsState, setContactUsState] = useState({
        name: '',
        nameError: '',
        description: '',
        descriptionError: '',
        website: '',
        websiteError: '',
        email: '',
        emailError: '',
        phoneNumber: '',
        phoneNumberError: '',
        scrollToElement: null
    });

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    useEffect(() => {
        const {nameError, emailError, phoneNumberError, websiteError, descriptionError} = contactUsState;


        if (nameError !== '') {
            scrollToErrorElement('name');
            return;
        }

        if (emailError !== '') {
            scrollToErrorElement('email');
            return;
        }

        if (websiteError !== '') {
            scrollToErrorElement('website');
            return;
        }

        if (phoneNumberError !== '') {
            scrollToErrorElement('phoneNumber');
            return;
        }

        if (descriptionError !== '') {
            scrollToErrorElement('description');
            return;
        }

        scrollToErrorElement('RESET')
    }, [contactUsState.nameError, contactUsState.emailError,
        contactUsState.websiteError, contactUsState.phoneNumberError, contactUsState.descriptionError]);

    const initializeErrorStateVariable = () => {
        setContactUsState((prevState) => ({
            ...prevState,
            nameError: '',
            emailError: '',
            phoneNumberError: '',
            websiteError: '',
            descriptionError: ''
        }))
    };

    const scrollToErrorElement = (name) => {

        if (name === 'RESET') {
            setContactUsState(prevState => ({
                ...prevState,
                scrollToElement: null
            }));
            return;
        }
        setContactUsState(prevState => ({
            ...prevState,
            scrollToElement: name
        }));
    };

    const handleChange = (inputName, value) => {
        setContactUsState((prevState) => ({
            ...prevState,
            [inputName]: value,
            [inputName + 'Error']: ''
        }));
    };

    const validateChanges = (field) => {
        const {name, email ,phoneNumber, website, description} = contactUsState;

        if (field === 'name' || field === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(name)) {
                setContactUsState((prev) => ({
                    ...prev,
                    nameError: 'Please enter name.'
                }));
                return false
            }
            if (name.length > 30) {
                setContactUsState((prev) => ({
                    ...prev,
                    nameError: 'Maximum 30 characters are allowed.'
                }));
                return false
            }
        }

        if (field === 'email' || field === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(email)) {
                setContactUsState((prev) => ({
                    ...prev,
                    emailError: 'Please enter email.'
                }));
                return false
            }
            if (!isEmpty(email) && !isValidEmail(email)) {
                setContactUsState((prevState) => ({
                    ...prevState,
                    emailError: "Enter a valid email address"
                }));
                return false;
            }
        }

        if (field === 'phoneNumber' || field === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(phoneNumber)){
                setContactUsState((prev) => ({
                    ...prev,
                    phoneNumberError: 'Please enter phone number.'
                }));
                return false
            }

            if (phoneNumber.length > 20) {
                setContactUsState((prev) => ({
                    ...prev,
                    phoneNumberError: 'Maximum 20 characters are allowed.'
                }));
                return false
            }
        }

        if (field === 'website' || field === APP_CONST.FORM_SUBMISSION) {
            if (!isEmpty(website) && (!website.startsWith('https://'))) {
                setContactUsState((prevState) => ({
                    ...prevState,
                    websiteError: "Enter https://"
                }));

                return false;
            }
        }

        if (field === 'description' || field === APP_CONST.FORM_SUBMISSION) {
            if (isEmpty(description)) {
                setContactUsState((prevState) => ({
                    ...prevState,
                    descriptionError: "Please enter description."
                }));

                return false;
            }
        }

        return true;
    };

    const handleSubmit = () => {
        const {name, email, phoneNumber, website, description} = contactUsState;
        initializeErrorStateVariable();  // This will trigger the useEffect for scrolling to error element.

        setTimeout(() => {
            if (!validateChanges(APP_CONST.FORM_SUBMISSION)) {
                return;
            }

            let contactUsData = {
                    name: name,
                    email: email,
                    phoneNumber: phoneNumber,
                    website: website,
                    message: description
            }

            contactUs(contactUsData).then((res)=>{
                if(res?.success){
                    store.dispatch(showToastMessage('success', 'Thank you for contacting us'))
                    setContactUsState((prevState)=>({
                        ...prevState,
                        name: '',
                        nameError: '',
                        description: '',
                        descriptionError: '',
                        website: '',
                        websiteError: '',
                        email: '',
                        emailError: '',
                        phoneNumber: '',
                        phoneNumberError: '',
                        scrollToElement: null
                    }))
                }else{
                    store.dispatch(showToastMessage('error', 'Failed to submit response'))
                }
            })
        }, 200)
    };

    const onBlurInputText = (name) => {
        validateChanges(name);
    };

    return (
        <ContactUsView
            {...props}
            {...contactUsState}
            validateChanges={validateChanges}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            //handleCheckBoxClick={handleCheckBoxClick}
            onBlurInputText={onBlurInputText}/>
    );
}


export default ContactUs;
